import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import news from '../assets/images/news.jpg';
import NavPages from '../components/NavPages';
import NewsCard from '../components/NewsCard';
import SliderGallery from '../components/SliderGallery';
import SEO from '../components/SEO';

const NewsPageStyled = styled.div`
  width: 100%;
  .news-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${news});
  }
  .news-container {
    max-width: var(--wrapper);
    width: 100%;
    margin: 4rem auto;
    padding: 2rem 2rem 3rem;
    &:nth-of-type(even) {
      background: var(--backgroundCard);
      border-radius: 8px;
    }
    h4 {
      text-align: center;
      margin: 1rem 0 2rem;
    }
  }
  .article-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: inherit;
    justify-items: stretch;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      padding: 0rem 2rem;
    }
  }
`;

export default function NewsPage({ data: { news } }) {
  const teamNews = news.nodes.filter((item) => item.category == 'team');
  const cultureNews = news.nodes.filter((item) => item.category == 'culture');
  const productNews = news.nodes.filter((item) => item.category == 'product');
  return (
    <>
      <SEO title="Latest News from Bont Rowing" />
      <NewsPageStyled>
        <div className="wrapper">
          <section className="page-header news-header">
            <div>
              <h2>News</h2>
            </div>
          </section>
          {teamNews.length > 0 && (
            <section className="news-container">
              <h4>Team News</h4>
              <div className="article-wrap">
                {teamNews.map((item) => (
                  <NewsCard
                    key={item.id}
                    title={item.title}
                    description={item.excerpt}
                    image={item.featuredImage.asset.fluid}
                    date={item._createdAt}
                    category={item.category}
                    slug={item.slug.current}
                  />
                ))}
              </div>
            </section>
          )}
          {cultureNews.length > 0 && (
            <section className="news-container">
              <h4>Culture News</h4>
              <div className="article-wrap">
                {cultureNews.map((item) => (
                  <NewsCard
                    key={item.id}
                    title={item.title}
                    description={item.excerpt}
                    image={item.featuredImage.asset.fluid}
                    date={item._createdAt}
                    category={item.category}
                    slug={item.slug.current}
                  />
                ))}
              </div>
            </section>
          )}
          {productNews.length > 0 && (
            <section className="news-container">
              <h4>Product News</h4>
              <div className="article-wrap">
                {productNews.map((item) => (
                  <NewsCard
                    key={item.id}
                    title={item.title}
                    description={item.excerpt}
                    image={item.featuredImage.asset.fluid}
                    date={item._createdAt}
                    category={item.category}
                    slug={item.slug.current}
                  />
                ))}
              </div>
            </section>
          )}
        </div>
        <NavPages />
      </NewsPageStyled>
    </>
  );
}

export const query = graphql`
  query NewsQuery {
    news: allSanityNews {
      nodes {
        excerpt
        slug {
          current
        }
        title
        id
        featuredImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        category
        _createdAt(formatString: "LL")
      }
    }
  }
`;
